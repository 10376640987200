<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="450px" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form"  layout="vertical">

      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>

        <a-input-number style='width:100%' v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]" />

        <a-time-picker disabled="true" :default-open-value="moment('00:00:00', 'HH:mm:ss')" @change="onChange"  v-if="item.type === 'time'" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />

        <a-select v-else-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :disabled="isEdit != 0" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>

      </a-form-item>

      <div class="followRecord-tagList">
          <a-tag  @click="addTextValue(item)" v-for="(item,index) in quickTags" :key="index">#{{item}}</a-tag>
      </div>

    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name:'time',
    label: '标记时间',
    type: 'time',
    rules: [{ required: true, message: '请输入标记时间!' }]
  },
  {
    name:'text',
    label: '标注名称',
    type: 'input',
    rules: [{ required: true, message: '请输入标注名称!' }]
  },
]

import moment from 'moment';
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    quickTags:Array
  },
  components: {},
  data() {
    return {
      item:false,
      formData,
      isClassesModal:0,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增标注',
      form: this.$form.createForm(this),
      seleteItems: {
        classes:[]
      },
      modalData: {},
      classesList:[],
    }
  },

  async created() {
    this.visible = true
    this.$ls.remove('localClassesList')
    
    await this.$nextTick()
    const { item, authArr } = this
    // this.form.setFieldsValue({time:100})
    this.form.setFieldsValue({time:moment(this.formatSeconds(this.parent.player.video.currentTime), 'HH:mm:ss')})
  },
  methods: {
    moment,
    addTextValue(item){
        this.form.setFieldsValue({text:item})
    },
    onChange(time, timeString) {
      console.log(time, timeString);
    },
    formatSeconds(value) {
        let result = parseInt(value)
        let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
        let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
        let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
        let res = '';
        res += `${h}:`;
        res += `${m}:`;
        res += `${s}`;
        return res;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async handleOk() {
      const { item, form } = this
      let params = await form.validateFields()
      params.time=this.parent.player.video.currentTime
      // console.log(this.parent.current);
      params.course_class_id = this.parent.current.course_class_id
      this.confirmLoading=true;
      try {
          await this.$store.dispatch('courseScheduleAddHighlightAction',params).then(res=>{
              this.$message.success('操作成功~')
              this.confirmLoading=false;
              this.parent.visible=false;
              this.parent.getHighlight(this.parent.current);
          })    
      } catch (error) {
          console.log(error)
      }
    },
    handleCancel() {
      this.parent.visible=false
    },
  }
}
</script>

<style lang="scss">
    .followRecord{
        &-tagList{
            display:flex;
            flex-wrap: wrap;
            .ant-tag{
                cursor: pointer;
                margin-bottom: 5px;
                color:#999 !important;
                border-color:#f5f7fa !important;
                border-bottom-left-radius:11px;
                border-top-left-radius:11px;
                border-top-right-radius:11px;
                border-bottom-right-radius:11px;
            }
        }
        .followRecord-inp{
            border: 1px solid #00cca2;
            border-radius: 3px;
            textarea{
                resize: none;
                border: none;
            }
            .ant-input:focus{
                border-color:#fff;
                box-shadow:none
            }
            .followRecord-btn{
                display: flex;
                background: rgb(249,249,249);
                div{
                    padding: 4px;
                }
            }
            .followRecord-img{
                padding-left: 15px;
            }
        }
        .followRecord-sel{
            display: flex;
            position: relative;
            margin-top: 10px;
            align-items: center;
            .followRecord-check{
                display: flex;
                align-items: center;
            }
            .followRecord-out{
                position: absolute;
                right: 0;
            }
            .followRecord-div{
                margin-right: 10px;
                span{
                    margin-right: 10px;
                }
            }
        }
        .followRecord-rec{
            border: 1px solid rgb(230,230,230);
            border-radius: 3px;
            padding: 10px;
            margin-top: 10px;
            overflow: scroll;
            height: 650px;
            .ant-list-item{
                padding: 6px 0;
            }
            .followRecord-rec-nav{
                display:flex;
                align-items:center;
                position:relative;
                width:100%;
                .followRecord-rec-img{
                    display:flex;
                    align-items:center;
                }
                .followRecord-rec-del{
                    position:absolute;
                    right:0
                }
                
            }
            .followRecord-rec-content{
                padding:6px 15px 6px 40px;
                img{
                    // width: 98px;
                    height: 98px;
                    margin: 5px;
                    border-radius: 3px;
                }
                .ant-tag{
                    color:#999 !important;
                    border-color:#f5f7fa !important;
                    border-bottom-left-radius:11px;
                    border-top-left-radius:11px;
                    border-top-right-radius:11px;
                    border-bottom-right-radius:11px;
                }
            }
        }
        .followRecord-divs{
            .ant-form-item{
                margin-bottom:0px;
            }
            .ant-calendar-picker-input{
                border: 1px solid #00cca2
            }

        }
    }
    .ant-dropdown-placement-bottomRight{
        z-index: 4300 !important;
    }

</style>
<template>
    <div>
    <div class="clearfix table-tools">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-radio-group :value="searchParams.search.finish_highlight">
                    <a-radio-button @click="handleTypeButChange('1','finish_highlight')" value="1">
                        已完成
                    </a-radio-button>
                    <a-radio-button @click="handleTypeButChange('0','finish_highlight')" value="0">
                        未完成
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    style="width: 240px"
                    :ranges="rangesData"
                    :value='rangesValue'
                    :allowClear="false"
                    :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                    @change="(date, dateString)=>onChange(date, dateString,2)" />
                </a-tooltip>
            </a-form-item>
            <a-form-item>
            <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>所属校区</span>
                </template>
                <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" style="width: 220px;">
                    <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
            </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
        
        </a-form>
    </div>
    <div class="common zlgx">                
        <div class="c-side">
            <LEmpty v-if="list.length==0"/>
            <div class="list">
                <perfect-scrollbar class="m-scrollbar" v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10">
                    <div class="item material-btns" :class="{ 'active': current.id === item.id }"  v-for="(item, index) of list" :key="index" @click="clickRecord(item)">
                        <div class="name">[{{ item.teacher_name }}] {{ item.title }}</div>
                        <a-dropdown v-if="!item.finish_highlight" placement="bottomRight" class='material-btns-icon'>
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                            <a-icon type="more" />
                        </a>
                        <a-menu slot="overlay">
                        <a-menu-item @click="finishHighlight($event, item)">
                            标记完成
                        </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <span class="material-btns-count">( {{item.highlight_count || 0}} )</span>
                    </div>
                </perfect-scrollbar>
            </div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
        </div>
        <div class="c-side">
            <vue-element-loading :active="highlightLoading" color="#00cca2" spinner="spinner"/>
            <div @click="clickhighlight($event)" class="material-left-nav" style="margin-left:10px;" v-if="current.id">
                <a-icon type="plus" />
                <span style="margin-left:10px">添加标注</span>
            </div>

            <div @click="clickclip($event)" class="material-left-nav" style="margin-left:10px;" v-if="current.id">
                <a-icon type="plus" />
                <span style="margin-left:10px">添加剪辑</span>
            </div>
            <LEmpty v-if="highlight.length==0"/>
            <div class="list">
                <perfect-scrollbar class="m-scrollbar">
                    <div class="item material-btns" :class="{ 'active': seekId === item.id }" 
                        v-for="(item, index) of highlight" :key="index" @click="clickSeek(item)">
                        <div class="name">{{ item.text }}</div>
                        <a-dropdown placement="bottomRight" class='material-btns-icon'>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-icon type="more" />
                            </a>
                            <a-menu slot="overlay">
                            <a-menu-item @click="deleteHighlight($event, item)">
                            删除
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <span class="material-btns-count">( {{formatSeconds(item.time)}} )</span>
                        <div class="btns material-btns">
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>

        <div class="c-main">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <LEmpty v-if="!current.id"/>
        <div class="zl-list" style="width:80%">
            <div v-if="current.teacher_name" class="title">
                <h3>【{{ current.teacher_name }}】 {{ current.title }}</h3>
            </div>
            <div>
                <div id="dplayer"></div>
                <!-- <canvas id="canvas" width="1448" height="814"></canvas> -->
                <!-- <div class="clip_rect"></div> -->
            </div>
            </div>
        </div>
    </div>
    <editModal v-if="visible" :quickTags="quickTags"/>
    <clipModal v-if="clipVisable"/>
    </div>
</template>

<script>
import DPlayer from 'dplayer';
import Hls from 'hls.js'
import url from '@/utils/URL'
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import infiniteScroll from 'vue-infinite-scroll'
import editModal from './editModal'
import clipModal from './clipModal'
import tableMixins from '@/common/mixins/table'

require("tracking/build/tracking-min.js");
require("tracking/build/data/face-min.js");
import dat from "dat.gui";
import * as faceapi from "face-api.js";

export default {
    name:'recall',
    components: {
        // subNode,
        clipModal,
        editModal
    },
    directives: { infiniteScroll },
    mixins: [ tableMixins , ranges ],
    provide() {
        return {
            parent: this
        }
    },
    beforeCreate () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }
    },
    created () {
        this.appid = this.$ls.get('appid')
        this.paramsData.start_date =  moment().add(-1,'week').format('YYYY-MM-DD')
        this.paramsData.end_date =moment().format('YYYY-MM-DD')
        if(this.$route.query.start_date){
            this.rangesValue = [moment(this.$route.query.start_date,'YYYY-MM-DD'),moment(this.$route.query.end_date,'YYYY-MM-DD')]
            this.paramsData.start_date = moment(this.$route.query.start_date).format('YYYY-MM-DD')
            this.paramsData.end_date = moment(this.$route.query.end_date).format('YYYY-MM-DD')
        }else{
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
        }
        // this.getRoom()
        // this.getTeacher()
        this.getStudioList()
        // this.getClassCategory()
    },
    mounted(){
    },
    data() {
        return {
            simpleImage:'',
            appid:'',
            visible:false,
            clipVisable:false,
            highlightLoading:false,
            highlight:[],
            modalData:false,
            player:false,
            busy: false,
            loading: false,
            imgCheckable: false,
            seekId:false,
            start_date:'',
            rangesValue:[],
            list: [],
            studios:[],
            albumList: [],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current:{},
            paramsData:{},
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 0,
                "per-page": 50,
                "search": {
                    is_sub:0,
                },
                "sort": ''
            },
            uploadUrl: url.uploadQiNiu, 
            uploadParams: {},
            quickTags:[],
            currentCategory: {},
            selectedKeys:[],
            selectedKeysF:[],
            uploadIndex:0,
            uploadIndexF:0,
            fileList:[],
            watermarker:'',
            showTree:false,
        }
    },
    methods: {
        async initTracking() {
            if(this.player){
                var video = this.player.video;
                var canvas = document.getElementById('canvas');
                var context = canvas.getContext('2d');
                var tracker = new tracking.ObjectTracker('face');
                //   设置识别的放大比例
                tracker.setInitialScale(4);
                //   设置步长
                tracker.setStepSize(2);
                //   边缘密度
                tracker.setEdgesDensity(0.1);
                //   启动摄像头，并且识别视频内容
                tracking.track('#video', tracker);
                tracker.on('track', function (event) {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    // 绘制
                    event.data.forEach(function (rect) {
                    context.strokeStyle = '#a64ceb';
                    context.strokeRect(rect.x, rect.y, rect.width, rect.height);
                    });
                });
            }
        },
        handleTypeButChange(e,name){
              if(this.searchParams.search[name] == e){
                this.searchParams.search[name] = undefined
              }else{
                this.searchParams.search[name] = e
              }
              this.getList()
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.searchParams.search.is_sub = e
        },
        deleteHighlight(e, item) {
            let that = this
            this.$confirm({
                title: `确定要删除该提示?`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                return new Promise(async (resolve, reject) => {
                    that.highlightLoading=true;
                    let res = await that.$store.dispatch('courseScheduleDelHighlightAction', {id: item.id })
                    if (res) {
                        that.$message.success('操作成功~')
                        that.getHighlight(that.current);
                        resolve(res)
                    }
                    that.highlightLoading=false;
                }).catch(error => console.log(error))}
            })
        },
        clickRecord(item){
            this.current=item;
            this.getHighlight(item);
        },
        formatSeconds(value) {
            let result = parseInt(value)
            let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
            let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
            let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
            let res = '';
            if(h !== '00') res += `${h}:`;
            if(m !== '00') res += `${m}:`;
            res += `${s}`;
            return res;
        },
        clickSeek(item) {
            this.seekId=item.id;
            this.$nextTick(()=>{
                this.player.seek(item.time);
            })
        },
        async finishHighlight(e, item) {
            //e.stopPropagation()
            let that = this
            this.$confirm({
                title: `确定完成标注?`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('courseRecallFinishAction', {course_class_id: item.course_class_id })
                    if (res) {
                    that.$message.success('操作成功~')
                    that.getList()
                    resolve(res)
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        async getStudioList() {
            let res = await this.$store.dispatch('searchBelongStudioAction', { })
            this.studios = res.data
        },
        async addHighLight() {
            let params ={};
            params.time=this.player.video.currentTime
            params.course_class_id = this.current.course_class_id
            try {
                await this.$store.dispatch('courseScheduleAddHighlightAction',params).then(res=>{
                    console.log(res);
                })    
            } catch (error) {
                console.log(error)
            }
        },
        async getHighlight(item){
            this.highlightLoading=true;
            let res = await this.$store.dispatch('courseScheduleHighlightAction',{course_class_id:item.course_class_id})
            this.highlight = res.data
            this.quickTags = res.quick_tag
            this.highlightLoading=false;
            if(!this.player){
                this.$nextTick(() => {
                    let playerOtions =  {
                        container: document.getElementById('dplayer'),
                        autoplay:false,
                        live: false,
                        muted: true,
                        screenshot:true,
                        preload: 'auto',
                        contextmenu:[],
                        highlight:this.highlight,
                        video: {
                            url: this.current.course_recall_url,
                            type: 'customHls',
                            customType: {
                                customHls: function (video, player) {
                                    const hls = new Hls();
                                    hls.loadSource(video.src);
                                    hls.attachMedia(video);
                                    player.events.on('destroy', () => {
                                        hls.stopLoad();
                                        hls.destroy();
                                    });
                                },
                            },
                        }
                    }
                    this.player = new DPlayer(playerOtions)
                    this.player.on('ended',()=>{
                        console.log('endd');
                    })
                    document.querySelector("#dplayer").oncontextmenu =  () => {
                        document.querySelector(".dplayer-menu").style.display = "none";
                        document.querySelector(".dplayer-mask").style.display = "none";
                        return false;
                    };
                })
            }else{
                this.$nextTick(() => {
                this.player.options.highlight= this.highlight
                this.player.switchVideo({
                    url: item.course_recall_url,
                    type: 'customHls',
                    customType: {
                        customHls: function (video, player) {
                            const hls = new Hls();
                            hls.loadSource(video.src);
                            hls.attachMedia(video);
                            player.events.on('destroy', () => {
                                hls.stopLoad();
                                hls.destroy();
                            });
                        },
                    }
                })
                document.querySelector("#dplayer").oncontextmenu =  () => {
                    document.querySelector(".dplayer-menu").style.display = "none";
                    document.querySelector(".dplayer-mask").style.display = "none";
                    return false;
                    };
                })
            }
        },
        clickhighlight(){
            this.visible=true;
        },
        clickclip(){
            this.clipVisable=true;
        },
        async getList() {
            this.loading=true;
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            this.searchParams.search.start_date = this.$route.query.start_date || this.paramsData.start_date || moment().add(-1,'week').format('YYYY-MM-DD')
            this.searchParams.search.end_date = this.$route.query.end_date || this.paramsData.end_date ||  moment().format('YYYY-MM-DD')
            let res = await this.$store.dispatch('courseRecallIndexAction', this.searchParams)

            this.watermarker = res._data.watermarker
            
            if(this.searchParams.page == 1){
                this.busy = false
                this.list=[];
                if(this.player){
                    this.player.destroy();
                    this.player=false;
                }
                if(res.items.length>0){
                    this.current= res.items[0]
                    this.getHighlight(this.current)
                }else{
                    this.highlight=[];
                }
            }else{
               this.busy = false 
            }
            if(res.items.length >0){
                this.list = [...this.list,...res.items]
            }
            if(res._meta.totalCount == this.list.length){
                this.busy = true
            }
            this.pageParams = res._meta

            this.loading=false;
        },
        handleInfiniteOnLoad() {
            if(this.searchParams.page !=0){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            }else{
                this.searchParams.page++
            }
        },
        sortKey(array, key) {
            return array.sort(function(a, b) {
                var x = a[key];
                var y = b[key];
                return x > y ? -1 : x < y ? 1 : 0;
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.clip_rect{
    width: 400px;
    height: 500px;
    border: 5px solid #999;
}
.m-scrollbar {
  height: calc(100vh - 280px);
}
.material-left-nav{
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.material-btns{
  display: flex;
  align-items: center;
  &-icon{
      position: absolute;
      right: 10px;
      cursor: pointer;
  }
  &-count{
      position: absolute;
      right: 25px;
      font-size: 12px;
      color: #999;
  }
}
</style>
<style lang="scss">
    // video,
    // canvas {
    //   position: absolute;
    // }
  .vue-select-image__item{
    margin-left:0px !important;
  }
  .vue-select-image__thumbnail{
    label{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        display:inline-block;
        text-align:center;
        width:100px;
        margin-top:4px;
    }
  }
  .image-wrap .ant-tree-switcher{
      display:none !important;
  }
  .image-wrap ul li ul{
      display:flex;
      flex-wrap:wrap;
  }
  .image-wrap ul li ul li{
      height:150px;
      margin:5px;
  }
  .image-wrap ul li ul li .ant-tree-node-content-wrapper{
      height:160px;
  }
</style>
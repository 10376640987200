<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>教学回顾</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">教学回顾</span>
          <recall v-if="activeKey === '1'"/>
        </a-tab-pane>

        <a-tab-pane key="2">
          <span slot="tab">剪辑记录</span>
          <clip v-if="activeKey === '2'"/>
        </a-tab-pane>
        
      </a-tabs>
    </div>
  </div>
</template>

<script>
import recall from './recall'
import clip from './clip'

export default {
  name: 'index',
  components: {
    recall,
    clip,
  },

  data() {
    return {
      activeKey: '1',
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

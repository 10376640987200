<template>
  <div>
      <div class="clearfix table-tools">


         <div class="buttons" v-if="false">
            <a-form layout='inline'>
                <a-form-item>
                    <a-button icon="setting" @click="toSetWatermarker">设置水印</a-button>
                </a-form-item>
            </a-form>
        </div>

        <div class="search">
          <a-form layout='inline' @submit="searchList">

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    style="width: 240px"
                    :ranges="rangesData"
                    :value='rangesValue'
                    :allowClear="true"
                    :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                    @change="(date, dateString)=>onChange(date, dateString,2)" />
                </a-tooltip>
            </a-form-item>

            <a-form-item class="block-line" >
              <a-select allowClear v-model="searchParams.search.studio_id" placeholder="请选择校区" showSearch :filterOption="filterOption" style="width:220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>

          </a-form>

        </div>
      </div>
      
      
      <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>  
        <div class="table">
            <div style="background-color: #ececec; padding: 20px;"  v-if="list.length>0" >
              <a-row :gutter="[24,8]" type="flex" justify="start">
                <a-col :span="6" v-for="(item,index) in list" :key="index">
                  <a-card hoverable :title="`${item.clip_name} - ${item.clip_by}`" :bodyStyle="bodyStyle">
                    <!-- <a-button @click="download(item)" slot="extra" size="small" icon="menu" :loading="exportLoading"></a-button> -->
                    <a-dropdown slot="extra">
                      <a-menu slot="overlay">
                        <a-menu-item key="1" @click="handleDelClick(item,1)"> 
                          <a>
                            <a-icon type="delete" /> 删除剪辑
                          </a>
                        </a-menu-item>
                        <a-menu-item key="2" v-if="item.clip_status"> 
                          <a target="_blank" rel="noopener noreferrer" :href="item.clip_path">
                          <a-icon type="download" />
                          下载剪辑
                          </a>
                        </a-menu-item>
                      </a-menu>
                      <a-button style="margin-left: 8px" size="small" icon="menu"></a-button>
                    </a-dropdown>
                    <div :id="`dplayer_${item.id}`"></div>
                  </a-card>
                  <!-- <a-card hoverable :title="`剪辑中。。。${item.clip_name} - ${item.clip_by}`" :bodyStyle="bodyStyle" v-if="!item.clip_status"> -->
                  </a-card>
                </a-col>
              </a-row>
            </div>
        </div>
        <div class="page">
          <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
              :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()} 条`"
          >
              <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
              </template>
          </a-pagination>
        </div>
      </div>
  
    <editModal v-if="editVisible" :item="modalData"/>
    <div @click="handleDetailOk" v-if="detail_visible" class="message-bg"></div>
  </div>
</template>

<script>
import DPlayer from 'dplayer';
// import Hls from 'hls.js'
import editModal from './editModal'
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'clip',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      exportLoading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      bodyStyle:{
        padding:'0px'
      },
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
          is_sub:0,
        },
        "sort": ''
      },
      paramsData:{},
      list: [],
      dp:[],
      studios: [],
      playerOtions: [],
      searchData: {},
      visible: false,
      modalData: {},
      editVisible: false,
      listVisible: false,
      detail_visible:false,
    }
  },
  mixins: [ ranges, tableMixins ],
  created() {
    this.getStudio()
    // this.paramsData.start_date =  moment().add(-1,'week').format('YYYY-MM-DD')
    // this.paramsData.end_date =moment().format('YYYY-MM-DD')
    if(this.$route.query.start_date){
        // this.rangesValue = [moment(this.$route.query.start_date,'YYYY-MM-DD'),moment(this.$route.query.end_date,'YYYY-MM-DD')]
        // this.paramsData.start_date = moment(this.$route.query.start_date).format('YYYY-MM-DD')
        // this.paramsData.end_date = moment(this.$route.query.end_date).format('YYYY-MM-DD')
    }else{
        this.rangesValue = []
    }
  },
  mounted() {
  },
  beforeCreate () {
      if(!this.$ls.get('is_sub')){
          this.$ls.set('is_sub', 0)
      }
  },
  beforeDestroy() {
    this.dp.map(dp=>{
      if(dp)
        dp.destroy();
    })
  },
  methods: {
    toSetWatermarker(){
        // console.log(this.watermarkerModalVisible)
        // this.watermarkerModalVisible=true;
    },
    handleDelClick(item){
      let that = this
      this.$confirm({
          title: `确定要删除该剪辑?`,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
          return new Promise(async (resolve, reject) => {
              that.highlightLoading=true;
              let res = await that.$store.dispatch('courseClipDeleteAction', {clip_id: item.id })
              if (res) {
                  that.$message.success('操作成功~')
                  that.getList();
                  resolve(res)
              }
              that.highlightLoading=false;
          }).catch(error => console.log(error))}
      })
      console.log(item)
    },
    handleIsSub(e){
        this.$ls.set('is_sub', e)
        this.searchParams.search.is_sub = e
    },
    download(item){
        const aLink = document.createElement('a');
        document.body.appendChild(aLink);
        aLink.style.display='none';
        const objectUrl = item.clip_path;
        aLink.href = objectUrl;
        aLink.target = '_blank';
        aLink.download = item.clip_path;
        aLink.click();
        document.body.removeChild(aLink);
    },
    onCopy(){
        this.$message.success('已复制到剪切板!')
    },
    onError(){
        this.$message.error('操作失败!')
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getPlay(){
      return ;
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getList() {
      this.loading = true
      this.searchParams.search.is_sub = this.$ls.get('is_sub')
      this.searchParams.search.start_date = this.paramsData.start_date
      this.searchParams.search.end_date = this.paramsData.end_date
      let res = await this.$store.dispatch('courseClipIndexAction', this.searchParams)
      this.list = res.items
      this.$nextTick(() => {
        this.list.map(item=>{
            if(item.clip_status==1){
              let option = {
                container: document.getElementById('dplayer_'+item.id),
                mutex:false,
                // volume:0,
                // preload:'none',
                autoplay:false,
                screenshot: true,
                live: false,
                video: {
                    url: item.clip_path,
                    type: 'mp4',
                  }
              }
              this.dp.push(new DPlayer(option))
            }
        })
      })
      
      this.pageParams = res._meta
      this.loading = false
    },

    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },

    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.listVisible = false
    },
    showDetailModal(item){
      this.modalData = item
      this.detail_visible = true
    },
    handleDetailOk(e) {
      this.detail_visible = false
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('notificationCenterDeleteAction', { data: { notify_id: item.notify_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
<style lang="scss">
  .notiDetailModal{
    img{
      width: 100%;
    }
  }
  .message-bg{
      position: fixed;
      z-index: 4000;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
  }
</style>